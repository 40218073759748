import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
// import { Container } from "reactstrap"
import MySwiper from "../components/swiper/swiper"
// import SampleForm from "../components/form/form"
import { LazyLoadImage } from "react-lazy-load-image-component"
import "react-lazy-load-image-component/src/effects/blur.css"
import about from "../images/about.jpg"
import ModalPop from "../components/modal/modal"
import ModalServiceBasic from "../components/modal/modalservicebasic"
import ModalServicePremium from "../components/modal/modalservicepremium"
import {
  FaGem,
  FaFunnelDollar,
  FaShoppingCart,
  FaQuoteLeft,
} from "react-icons/fa"

class IndexPage extends React.Component {
  render() {
    const siteTitle = this.props.data.site.siteMetadata.title
    const siteDescription = this.props.data.site.siteMetadata.description
    const posts = this.props.data.allSanityPost.edges
    console.log(posts)
    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={siteTitle} description={siteDescription} />
        <div className="hero">
          <div className="hero-content">
            <h5 data-aos="fade" data-aos-duration="300" data-aos-delay="900">
              24/7 SUPPORT
            </h5>
            <h1
              data-aos="fade-up"
              data-aos-duration="600"
              data-aos-delay="1200"
              className="font-weight-bolder mt-3 mt-md-5"
            >
              Boilerplate Services &amp; Maintenance
            </h1>
            <h4 data-aos="fade-up" data-aos-duration="600" data-aos-delay="500">
              We provide a wide range of services from critical repairs to plant
              outage services, safety testing, efficiency upgrades, and
              equipment cleaning and inspections.{" "}
            </h4>
            <div class="d-block">
              <div class="d-md-block">
                <Link
                  to="/contact"
                  className="btn-1 main-cta btn btn-secondary"
                >
                  Free Consultation
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="callout">
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <div
                  data-aos="fade"
                  className="callout_content shadow mb-3 aos-init aos-animate"
                >
                  <div className="services-icon">
                    <FaGem />
                  </div>
                  <h4 className="text-darker">
                    Wide Range of <br />
                    Experience &amp; Training
                  </h4>
                  <p>
                    We provide a wide range of services from critical repairs to
                    plant outage services, safety testing, efficiency upgrades,
                    and equipment cleaning and inspections. All at affordable
                    rates.
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div
                  data-aos="fade"
                  data-aos-delay="300"
                  className="callout_content shadow mb-3 aos-init aos-animate"
                >
                  <div className="services-icon">
                    <FaFunnelDollar />
                  </div>
                  <h4 className="text-darker">
                    {" "}
                    Save on Maintenance <br />
                    &amp; Operation Costs
                  </h4>
                  <p>
                    Save on maintenance and operation costs and improve the
                    operation and overall life of your entire system. Ask us
                    about our discounts and available rebates.
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div
                  data-aos="fade"
                  data-aos-delay="600"
                  className="callout_content shadow aos-init aos-animate"
                >
                  <div className="services-icon">
                    <FaShoppingCart />
                  </div>
                  <h4 className="text-darker">
                    Purchase Parts &amp; <br />
                    Services Online
                  </h4>
                  <p>
                    We can help you with all phases of boiler system needs; from
                    start- ups to tune-ups, repairs, maintenance, and parts
                    replacement.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="about">
          <div className="container">
            <div className="row">
              <div className="col-md-5">
                <div className="about_banner">
                  <LazyLoadImage
                    data-aos="fade"
                    className="img-fluid aos-init aos-animate"
                    src={about}
                  />
                </div>
              </div>
              <div className="col-md-7">
                <div className="about_content">
                  <h5 data-aos="fade" className="aos-init aos-animate">
                    ABOUT US
                  </h5>
                  <h2
                    data-aos="fade-up"
                    data-aos-delay="300"
                    className="text-darker aos-init aos-animate"
                  >
                    We have 20+ years of <br />
                    Professional Experience
                  </h2>
                  <p
                    data-aos="fade-up"
                    data-aos-delay="600"
                    className="aos-init"
                  >
                    At Intermountain BoilerworX, Inc., our goal is to not-only
                    help you with all of your boiler system needs, but to ensure
                    that your boiler system is an asset rather than a liability.{" "}
                  </p>
                  <ul
                    className="list-inline styled-list text-darker my-4 aos-init"
                    data-aos="fade-up"
                    data-aos-delay="600"
                  >
                    <li className="list-inline-item">
                      Factory-trained Technicians
                    </li>
                    <li className="list-inline-item">
                      Online Purchase &amp; Parts
                    </li>
                    <li className="list-inline-item">Onsite Training</li>
                    <li className="list-inline-item">Affordable rates</li>
                    <li className="list-inline-item">24/7 Support</li>
                    <li className="list-inline-item">
                      Full-Service Customer Care
                    </li>
                  </ul>
                  <div class="d-md-block mr-5">
                    <Link
                      to="/contact"
                      className="btn-1 main-cta btn btn-secondary"
                    >
                      Free Consultation
                    </Link>
                  </div>
                  {/*<Link
                    data-aos="fade"
                    className="btn btn-secondary-inverted aos-init"
                    to="/"
                  >
                    MEET THE TEAM
                  </Link>*/}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="services">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="section-heading">
                  <h5 data-aos="fade" className="aos-init aos-animate">
                    OUR SERVICES
                  </h5>
                  <h2
                    data-aos="fade-up"
                    data-aos-delay="300"
                    className="text-darker aos-init aos-animate"
                  >
                    We have 20+ years of <br />
                    Professional Experience
                  </h2>
                  <p
                    data-aos="fade-up"
                    data-aos-delay="600"
                    className="text-darker aos-init"
                  >
                    At Intermountain BoilerworX, Inc we maintains professional
                    relationships with excellent service providers and
                    representatives who are experts in their fields for Chemical
                    Treatment, NDE, Code Repairs, New Equipment, and other
                    special needs.
                  </p>
                </div>
                <div className="d-sm-flex justify-content-center package">
                  <div
                    data-aos="fade"
                    data-aos-delay="300"
                    className="package_type shadow aos-init"
                  >
                    <h3 className="gradient-secondary px-4 px-lg-5 py-3">
                      Basic
                    </h3>
                    <ul className="list-unstyled styled-list text-darker">
                      <li>Burners and controls</li>
                      <li>Refractory services</li>
                      <li>Steam Trap Troubleshooting</li>
                      <li>Item Emergency repairs</li>
                      <li>Tube replacement</li>
                      <li>Onsite Training</li>
                    </ul>
                    <div className="text-center mb-4 p-3">
                      <div class="d-md-block">
                        <Link
                          to="/services#services"
                          className="btn-1 main-cta btn btn-secondary"
                        >
                          VIEW MORE SERVICES
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div
                    data-aos="fade"
                    data-aos-delay="600"
                    className="package_type shadow aos-init"
                  >
                    <h3 className="gradient px-4 px-lg-5 py-3">Special</h3>
                    <ul className="list-unstyled styled-list text-darker">
                      <li>Tube Cleaning</li>
                      <li>Weld Services</li>
                      <li>Plant Outages</li>
                      <li>Heat Exchanger Services</li>
                      <li>Emergency Tube Plugging</li>
                      <li>Boiler Rental</li>
                    </ul>
                    <div className="text-center mb-4 p-3">
                      <Link
                        to="/services#services"
                        className="btn-1 main-cta btn btn-secondary"
                      >
                        VIEW MORE SERVICES
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="content-footer text-center" />
              </div>
            </div>
          </div>
        </div>
        <div className="testimonials">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="testimonials_content">
                  <div className="fas">
                    <FaQuoteLeft />
                  </div>
                  <h2 className="text-white">
                    &nbsp;&nbsp;Our Happy Customers
                  </h2>
                  <MySwiper slidesperview={1}>
                    <div>
                      <h3 className="font-weight-light">
                        Dan and his team are proficient and dedicated!
                        Integrity, knowledge, and customer satisfaction are the
                        foundation of Intermountain! The Best in all of
                        Colorado!
                      </h3>
                      <span className="btn-secondary py-1 px-3">
                        Christian Phillips
                      </span>
                    </div>
                    <div>
                      <h3 className="font-weight-light">
                        Dan was a great contractor! He communicated well, and is
                        clearly an expert. No Complaints. The boiler is now in
                        great condition! Would hire him again!
                      </h3>
                      <span className="btn-secondary py-1 px-3">Roman</span>
                    </div>
                  </MySwiper>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="call-a-tech">
          <div className="container">
            <div className="row">
              <div className="col-md-6" />
              <div className="col-md-6">
                <div className="call-a-tech_content">
                  <h2 className="text-white">Ask a Tech</h2>
                  <p>
                    Our team is happy to help so please indicate the best way to
                    contact you and we will answer the inquiry within 24 hours.
                  </p>
                  <p className="text-white">
                    For immediate assistance please call:{" "}
                    <a href="tel:3038450432">(303) 845-0432</a>
                  </p>
                  <div class="d-md-block mr-5">
                    <Link
                      to="/ask-a-tech"
                      className="btn-1 main-cta btn btn-secondary"
                    >
                      ASK A TECH NOW
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default IndexPage

export const indexPageQuery = graphql`
  query indexPageQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
    allSanityPost(sort: { order: ASC, fields: publishedAt }, limit: 3) {
      edges {
        node {
          id
          title
          mainImage {
            asset {
              fluid {
                src
              }
            }
          }
          slug {
            current
          }
          excerpt
          publishedAt(formatString: "MMMM DD, YYYY")
        }
      }
    }
  }
`
